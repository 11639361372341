<template>
  <p-dialog-content>
    <iframe :src="path" height="100%" width="100%">
    </iframe>
  </p-dialog-content>
</template>

<script>
  import { mapGetters } from 'vuex'
  import TokenService from "@/services/TokenService"
  import { getConfig } from "@/config"

const config = getConfig();

  export default {
    name: "PurchaseDialogInvoice",
    props: {
      orderId: {
        type: String
      }
    },
    computed: {
      ...mapGetters(['env']),
      path () {
        return config.apiUrl + "InvoiceDocument?inline=true&type=IOGRUNNLAG&orderId=" + this.orderId + '&authtoken=' + encodeURIComponent(TokenService.getToken())
      }
    },
  }
</script>