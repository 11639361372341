<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
    <div class="flex-1 overflow-y-scroll relative">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.Booking')" class="w-24"></th>
            <th v-html="$tk('Reports.Sent')" class="w-24"></th>
            <th v-html="$tk('Reports.DeliveryDateShort')" class="w-24 whitespace-no-wrap"></th>
            <th v-html="$tk('Reports.Type')" class="w-14"></th>
            <th v-html="$tk('Reports.Status')"></th>
            <th v-html="$tk('Reports.From')"></th>
            <th v-html="$tk('Reports.To')"></th>
            <th v-html="$tk('Reports.Product')" class="w-72"></th>
            <th v-html="$tk('Reports.ProductTypeShort')" title="Produkt type / product type" class="whitespace-no-wrap w-14"></th>
            <th v-html="$tk('Reports.Qty')" title="Antall / Amount" class="right w-14"></th>
            <th v-html="$tk('Reports.Sort')" title="Sortert / Sorted" class="right w-14"></th>
            <th v-html="$tk('Reports.Rep')" title="Reparert / Repaired" class="right w-14"></th>
            <th v-html="$tk('Reports.Currency')" class="w-8" ></th>
            <th v-html="$tk('Reports.Amount')" class="right w-14"></th>
            <th v-html="$tk('Reports.Shipping')" class="right w-14"></th>
            <th v-html="$tk('Reports.Total')" class="right w-14"></th>
            <th v-html="$tk('Reports.SendersRef')" title="Avsenders ref / senders ref" class="whitespace-no-wrap"></th>
            <th v-html="$tk('Reports.RecieversRef')" title="Mottakers ref / recievers ref" class="whitespace-no-wrap"></th>
            <th></th>
          </tr>
        </thead>
        <template v-for="(order, index) in orders">
          <transaction :order="order" :productFilter="product" :key="index" />
        </template>
      </table>

      <portal to="header">
        <div class="ml-1 text-xl font-medium text-gray-900">
          <PTranslation k="Reports.Transactions" />          
          </div>
      </portal>

      <portal to="filters">
        <div class="flex space-x-2">
          <p-select class="w-48" v-model="selectedLocation" :items="locations" itemText="name" itemValue="id" :placeholder="$t('Reports.AllLocations')" />
          <p-select class="w-40" v-model="orderType" :items="orderTypes" itemText="text" itemValue="value"  :placeholder="$t('Reports.AllOrderTypes')" />
          <p-select class="w-44" v-model="product" :items="products" itemText="name" itemValue="id"  :placeholder="$t('Reports.AllProducts')" />
          <p-date-picker class="w-36" :placeholder="$t('Common.General.FromDate')" v-model="dateLower" :clearable="true" :allOpen="true" />
          <p-date-picker class="w-36" :placeholder="$t('Common.General.ToDate')" v-model="dateUpper" :clearable="true" :allOpen="true" :rightAlign="true" />
        </div>
      </portal>

      <portal to="actions">
        <div class="flex ml-2 space-x-2">
          <PButton 
            color="secondary" 
            icon="file-excel" 
            :loading="isDownloading"
            @click="onDownload"
          >
            Excel
          </PButton>
        </div>
      </portal>
    </div>
  </div>
</template>

<script>
  import http from "@/http"
  import Transaction from "../components/Transaction"
  import { format, sub, startOfMonth, lastDayOfMonth } from "date-fns"
  import { map, orderBy, filter} from "lodash"
  import { mapGetters } from 'vuex'
  import fileDownload from 'js-file-download'
  
  export default {
    name: "Transactions",
    data() {
      return {
        dateUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
        dateLower: format(startOfMonth(sub(new Date(), { months: 2 })), "yyyy-MM-dd"),
        selectedLocation: -1,
        locationPicker: false,
        locations: [],
        fees: true,
        sort: true,
        rep: true,        
        isLoading: true,
        isDownloading: false,
        orders: [],
        orderType: "TO,HE,OO,GL",
        orderTypes: [],
        products: [],
        product: '-1',
        productTypes: [ 
          { text: this.$tk('Reports.AllProductTypes', true), value: 0},
          { text: this.$tk('Reports.PurchaseAndSale', true), value: 1 },
          { text: this.$tk('Reports.Commission', true), value: 2 },
          { text: this.$tk('Reports.Pooling', true), value: 3 } 
        ]
      }
    },
    components: {
      Transaction
    },
    computed: {
      
      ...mapGetters(['location', 'user']),
      
      queryState () {
        return this.dateLower + '-' + this.dateUpper +  '-' + this.orderType + '-' + this.selectedLocation
      },

      params () {

        let obj = {
          transactiontype: this.orderType,
          withFees: this.fees,
          withSort: this.sort,
          withQualityChanges: true,
          sendLower: this.dateLower,
          sendUpper: this.dateUpper,
        }
       if (this.selectedLocation !== -1) {
          obj.locationId = this.selectedLocation
        } else if (this.locationPicker) {
          obj.customerId = this.user.customerId
        } else {
          obj.locationId = this.location.id
        }

        if (this.productType) {
          obj.productType = this.productType
        }

        return obj

      }

    },
    watch: {
      async queryState() {
        try {
          this.isLoading = true
          this.orders = await this.loadOrders()
        } finally {
          this.isLoading = false
        }
      }
    },
    methods: {      

      async loadOrders() {        
        const orders = await http.get("orders", { params: this.params })
        return orderBy(orders, "id", "asc")
      },

      async loadLocations() {
        const locations = await http.get('locations', { params: { customerId: this.user.customerId }})
        locations.unshift({ name: this.$tk('Reports.AllLocations', true), id: -1})
        return locations
      },

      async loadProducts() {
        const products = await http.get('products', { params: { all: true }})
        products.unshift({ name: this.$tk('Reports.AllProducts', true), id: '-1'})
        return products
      },

      async onDownload () {

        let params = {
          ...this.params,
          excel: "download"
        }
        this.isDownloading = true
        const data = await http.get("orders", { params: params, responseType: "blob" })
        fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_transactions.xlsx`)
        this.isDownloading = false
      }
    },

    async created () {
      try {
        this.rep = this.location.isForcedRep === 1
        this.locationPicker = this.user.reportLevel === 1

        if (this.locationPicker) {
          this.locations = await this.loadLocations()
        }

        this.products = await this.loadProducts()
        
        this.orderTypes = filter(
          map(await http.get('TransactionTypes'), r => {
            return { value: r.internalCode, text: r.externalName }
          }), 
          r => {             
            return this.orderType.includes(r.value) 
          }
        )
        this.orderTypes.unshift({ value: map(this.orderTypes, r => r.value).join(','), text: this.$tk('Reports.AllOrderTypes', true)})
        this.orders = await this.loadOrders()
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  }
</script>

<style scoped>
thead > tr >th {
  @apply p-2 sticky;
}

</style>
